<template lang="pug">
div(ref='container' class='popover-container')
  slot(name='default' :toggle='toggle' :visible='isVisible')
teleport(:to='teleportTarget')
  transition(:name='transition')
    div(v-if='isVisible' ref='content' v-bind='$attrs' :class='cssClasses' :style='floatingStyles' class='popover')
      div(v-if='position !== false' ref='floatingArrow' :style='arrowStyles' class='popover__arrow')
      btn(v-if='closeable' icon='x' small plain :data-tooltip='tooltipClose' data-test-btn='tooltip-close' class='popover__close' @click='close')
      div(v-if='$slots.title' class='popover__title')
        slot(name='title')
      div(class='popover__text')
        slot(name='content' :close='close')
</template>

<script lang="ts">
import { type Placement } from '@floating-ui/core'
import { useFloating, arrow, autoPlacement, shift, offset, autoUpdate, type Side } from '@floating-ui/vue'
import { onClickOutside, onKeyStroke } from '@vueuse/core'
import {
  defineComponent,
  type PropType,
  ref,
  computed,
  type SetupContext,
  type StyleValue,
  onMounted,
  watch,
} from 'vue'

export type PopoverType = 'default' | 'dark' | 'success' | 'danger'
export type PopoverSize = 'sm' | 'base' | 'lg'
const STATIC_SIDES: Record<Side, Side> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}
const Popover = defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: { type: Boolean },
    dark: Boolean,
    teleport: Boolean,
    tooltipClose: String,
    teleportTarget: { type: String, default: 'main' },
    offset: { type: Number, default: 10 },
    target: Object as PropType<HTMLElement>,
    closeOnClickOutside: Boolean,
    closeable: { type: Boolean, default: true },
    transition: { type: String, default: 'fade' },
    indented: Boolean,
    flip: { type: Boolean, default: true },
    padding: { type: Number, default: 20 },
    scrollIntoView: { type: Boolean, default: true },
    position: { type: [String, Boolean] as PropType<Placement | boolean | undefined>, default: undefined },
    size: { type: String as PropType<PopoverSize>, default: 'base' },
    type: { type: String as PropType<PopoverType>, default: 'default' },
    allowedPlacements: { type: Array as PropType<Placement[]> },
  },
  setup(props, context: SetupContext) {
    const visible = ref(false)
    const content = ref<null | HTMLElement>(null)
    const container = ref<null | HTMLElement>(null)
    const floatingArrow = ref<null | HTMLElement>(null)
    const actualTarget = computed(() => (props.position === false ? null : props.target || container.value))
    const isVisible = computed(() => props.modelValue)

    const { floatingStyles, middlewareData, placement } = useFloating(actualTarget, content, {
      whileElementsMounted: autoUpdate,
      ...(props.position ? { placement: props.position as Placement } : {}),
      middleware: [
        ...(!props.position
          ? [props.allowedPlacements ? autoPlacement({ allowedPlacements: props.allowedPlacements! }) : autoPlacement()]
          : []),
        shift({ padding: 20, boundary: document.querySelector('main')! }),
        offset(props.offset),
        arrow({ element: floatingArrow }),
      ],
    })
    const arrowStyles = computed<StyleValue>(() => {
      const staticSide = STATIC_SIDES[placement.value.split('-')[0] as Side]
      return {
        position: 'absolute',
        visibility: 'visible',
        left:
          middlewareData.value.arrow?.x != null && staticSide !== 'right' ? `${middlewareData.value.arrow.x}px` : '',
        top:
          middlewareData.value.arrow?.y != null && staticSide !== 'bottom' ? `${middlewareData.value.arrow.y}px` : '',
        [staticSide]: '-6px',
      }
    })

    const scrollIntoViewIfAllowed = () =>
      props.modelValue &&
      content.value &&
      setTimeout(() => content.value?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' }), 300)

    onMounted(() => {
      if (props.scrollIntoView) {
        scrollIntoViewIfAllowed()
        watch(() => [actualTarget.value, props.modelValue], scrollIntoViewIfAllowed)
      }
    })

    const close = () => {
      context.emit('update:modelValue', visible.value)
      context.emit('onClose', visible.value)
    }

    const open = () => {
      context.emit('update:modelValue', visible.value)
      context.emit('onOpen', visible.value)
    }

    const toggle = () => (props.modelValue ? close() : open())

    onKeyStroke('Escape', () => close())

    if (props.closeOnClickOutside) {
      onClickOutside(content as any, () => close())
    }

    return {
      floatingStyles,
      actualTarget,
      cssClasses: computed<{ [className: string]: boolean }>(() => ({
        'popover--indented': props.indented,
        'popover--centered': props.position === false,
        [`popover--${props.type}`]: true,
        [`popover--${props.size}`]: true,
      })),
      close,
      open,
      floatingArrow,
      arrowStyles,
      container,
      toggle,
      content,
      isVisible,
      visible,
    }
  },
})
export default Popover
</script>
<style lang="stylus">
@import '../styles/variables.styl'

:root
  --popover-bg: #fff
  --popover-width: 250px
  --popover-text-color: $color-outer-space
  --popover-arrow-size: 16px
  --popover-arrow-offset: 32px
  --popover-padding: 20px
  --popover-border-radius: 9px
  @media (max-width: $breakpoint-xs-max)
    --popover-arrow-offset: 28px
    --popover-arrow-size: 10px

.popover
  --bleed-top: 20px
  --bleed-right: 20px
  --bleed-left: 20px
  --video-border-radius: var(--popover-border-radius) var(--popover-border-radius) 0 0
  background: var(--popover-bg)
  box-shadow: 0 0.2px 2.1px rgba(0, 0, 0, 0.022), 0 0.4px 5.2px rgba(0, 0, 0, 0.031), 0 0.9px 10.6px rgba(0, 0, 0, 0.039), 0 1.8px 21.9px rgba(0, 0, 0, 0.048), 0 5px 60px rgba(0, 0, 0, 0.07)
  box-sizing: border-box
  pointer-events: all
  line-height: 1.4
  position: absolute
  scroll-margin 120px
  z-index: 9999
  border-radius: var(--popover-border-radius)
  width: var(--popover-width)
  max-width: calc(100vw - 30px)
  &__arrow
    visibility: hidden
    ~/__content--centered &
      display: none
    &, &:before
      position: absolute
      width: var(--popover-arrow-size)
      height: var(--popover-arrow-size)
      z-index: -1
    &:before
      background: var(--popover-bg)
      visibility: visible
      content: ''
      transform: rotate(45deg)
  &--base
    --popover-width: 300px
  &--sm
    --popover-padding: 12px
    --popover-arrow-size: 14px
    --popover-arrow-offset: 26px
    @media (max-width: $breakpoint-xs-max)
      --popover-arrow-offset: 18px
      --popover-arrow-size: 12px
  &--md
    --popover-width: 380px
  &--lg
    --popover-width: 500px
    --popover-padding: 42px
  &__close
    position: absolute!important
    top: 16px
    right: 8px
    z-index: 99!important
    color: var(--popover-text-color)!important
  &__title
    font-weight: 800
    padding: var(--popover-padding) var(--popover-padding) 10px var(--popover-padding)
    font-size: 16px
    font-family: $font-lato
    ~/--lg &
      padding-bottom: 25px
      font-size: 26px
      font-weight: 400
      -webkit-font-smoothing: antialiased
  &__text,
  &__title
    ~/--indented &
      padding-right: 45px
  &__text
    padding: var(--popover-padding) var(--popover-padding) var(--popover-padding) var(--popover-padding)
    font-size: 16px
    max-height: 90vh
    overflow: auto
    color: var(--popover-text-color)
    br
      content: ''
      height: 10px
      display: block
    p
      font-weight: 400
      line-height: 1.65
      font-size: 16px
    ~/__title + &
      padding:  0px var(--popover-padding) var(--popover-padding)
  &--success
    --popover-bg: var(--green-400)
    --popover-text-color: #fff
  &--danger
    --popover-bg: var(--red-500)
    --popover-text-color: #fff
  &--dark
    --popover-bg: var(--grey-900)
    --popover-text-color: #fff
  &--centered
    box-shadow: -2px 2px 22px rgba(0,0,0,.25)
    left: 50%!important
    top: min(40vh, 300px)!important
    position: fixed!imporetant
    transform: translateX(-50%) translateY(-50%)!important
</style>
